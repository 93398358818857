<template>
  <div class="home-componet-wrap">
    <at-home-banner></at-home-banner>
    <at-home-info-stripe></at-home-info-stripe>

    <section class="home-category-sec">
      <div class="container">
        <div class="row">
          <div class="col col-md-12">
            <div class="category-slider">
              <div v-if="typeof images == 'object'">
                <at-carousel-slider :initialConf="{ slidesToShow: 3, slidesToScroll: 1 }">
                  <div v-for="image of images.slides" :key="image.id">
                    <at-categoryBox :link="image.link" :imageUrl="image.media.url" :buttonText="image.buttonText">
                    </at-categoryBox>
                  </div>
                </at-carousel-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="home-multi-slider" v-if="newInRangeProducts.length">
      <b-container>
        <div class="section-title">
          <h2>Nieuw in het assortiment</h2>
        </div>
        <MoreProductsCarousel :products="newInRangeProducts" />
      </b-container>
    </div>

    <div class="home-multi-slider" v-if="inActionProducts.length">
      <b-container>
        <div class="section-title">
          <h2>In de actie</h2>
        </div>
        <MoreProductsCarousel :products="inActionProducts" />
      </b-container>
    </div>

    <at-call-to-action v-if="stories && stories.length" :urlKey="stories.url_key" :baseImage="stories.base_image"
      :heading="stories.name" :description="stories.description"></at-call-to-action>

    <div class="home-multi-slider" v-if="bestSellersProducts.length">
      <b-container>
        <div class="section-title">
          <h2>Best sellers</h2>
        </div>
        <MoreProductsCarousel :products="bestSellersProducts" />
      </b-container>
    </div>

    <section class="blog-sec home">
      <div class="container">
        <div class="row">
          <div class="col col-md-12">
            <at-carousel-slider :initialConf="{ slidesToShow: 3, slidesToScroll: 1 }">
              <div v-for="blog in blogs" :key="blog.post_id">
                <at-blog-box :isHome="true" :featuredImage="blog.featured_image" :title="blog.title"
                  :shortFilteredContent="blog.short_filtered_content" :identifier="blog.identifier"></at-blog-box>
              </div>
            </at-carousel-slider>
          </div>
        </div>
        <div class="row">
          <div class="col col-md-12">
            <div class="blog-read-more text-right">
              <b-link :to="{ name: 'blogs' }" class="regular-15">
                <u>{{ $t("view_all_blog") }}</u>
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AtHomeBanner from "@/base/core/components/home/HomeBanner.vue";
import AtHomeInfoStripe from "@/base/core/components/home/HomeInfoStripe.vue";
import AtCallToAction from "@/base/core/components/home/CallToAction.vue";
import AtCarouselSlider from "@/base/core/components/wrappers/CarouselSlider.vue";
import AtCategoryBox from "@/base/core/components/category/CategoryBox.vue";
import AtBlogBox from "@/base/core/components/blog/BlogBox.vue";
import MoreProductsCarousel from "@/base/core/components/product-detail/MoreProductsCarousel.vue";

export default {
  components: {
    AtHomeBanner,
    AtHomeInfoStripe,
    AtCallToAction,
    AtCarouselSlider,
    AtCategoryBox,
    AtBlogBox,
    MoreProductsCarousel,
  },
  computed: {
    images() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "home_gallery"
      );
    },

    stories() {
      // console.log("story", this.$store.getters["stores/getStores"]);
      const data = this.$store.getters["stores/getStores"];
      const random = Math.floor(Math.random() * data.length);
      return data[random];
    },

    blogs() {
      let data = [...this.$store.getters["blog/getCurrentPage"]];
      const shuffled = data.sort(() => 0.5 - Math.random());

      return shuffled.slice(0, 3);
    },

    newInRangeProducts() {
      return this.$store.getters["home/getNewInRangeProducts"];
    },

    inActionProducts() {
      return this.$store.getters["home/getInActionProducts"];
    },

    bestSellersProducts() {
      return this.$store.getters["home/getBestSellers"];
    },
  },
};
</script>

<style>
</style>